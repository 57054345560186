import React from 'react';
import Link from 'next/link';
import { SideBar } from '../components/layout/style';
import SongsBlock from '../components/layout/SongsBlock';
import { ErrorPage, ErrorPageContent, ErrorTitle, getErrorMessage } from './_error';

export default function NotFoundPage({ statusCode = 0 }) {
  return <>
    <ErrorPage>
      <ErrorTitle>{statusCode}</ErrorTitle>
      <ErrorPageContent>
        {getErrorMessage(statusCode)}
        <br/>
        <Link href='/'><a>На главную</a></Link>
      </ErrorPageContent>
    </ErrorPage>
    <SideBar>
      <SongsBlock/>
    </SideBar>
  </>
}
