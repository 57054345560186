import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { PageContent, SideBar } from '../components/layout/style';
import SongsBlock from '../components/layout/SongsBlock';

export const ErrorPage = styled(PageContent)`
    text-align: center;
`;

export const ErrorPageContent = styled.div`
    font-size: 20px;
`;

export const ErrorTitle = styled.div`
    padding: 26px;
    font-size: 48px;
    font-family: 'Ruslan Display', cursive;
`;

export const getErrorMessage = (code) => code === 404 ? 'Страница не найдена' : 'Что-то пошло не так (';

export default function NotFoundPage({ statusCode = 0 }) {
    return <>
        <ErrorPage>
            <ErrorTitle>{statusCode}</ErrorTitle>
            <ErrorPageContent>
                {getErrorMessage(statusCode)}
                <br/>
                <Link href='/'><a>На главную</a></Link>
            </ErrorPageContent>
        </ErrorPage>
        <SideBar>
            <SongsBlock/>
        </SideBar>
    </>
}

NotFoundPage.getInitialProps = ({ res }) => {
    return {
        statusCode: res && res.statusCode ? res.statusCode : 500,
    }
};
